import {tableStores} from "../store/tableModules"

export const useTableFeatures = (module: string) => {
    const tableStore = tableStores[module]()

    const fetchData = (refresh?: boolean) => {
        if (!tableStore) return
        tableStore?.fetchData(refresh)
    }

    return {
        tableStore,
        fetchData
    }
}
